import React from 'react'
import { Image, Container } from 'semantic-ui-react';
// import Layout from '../components/Layout'
import HomeLayout from '../components/HomeLayout'
import styled from 'styled-components';
import img from '../../static/img/updated-why-2.svg';

const NotFoundPage = styled(({ className }) => (
  <HomeLayout>
    <Container className={className}>
      <div className="col-left">
        <Image src={img} alt="404 Not found" />
      </div>
      <div className="col-right">
        <div className="text-h1 txt-color-3">Uh oh!</div>
        <p className="text-h3">We couldn't find that page.</p>
        <p className="text-p">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Container>
  </HomeLayout>
))`
  .text-h1 {
    font-weight: 900;
  }
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 60vh;
  .col-left {
    flex:1;
    min-width: 320px;
  }
  .col-right {
    padding: 20px;
    min-width: 320px;
    flex:1;
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
  }
`;

export default NotFoundPage
